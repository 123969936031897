export const navLinks = [
  {
    id: "inicio",
    title: "Inicio",
    link: "/",
  },
  {
    id: "bachillerato",
    title: "Bachillerato",
    link: "/bachillerato",
  },
  {
    id: "licenciaturas",
    title: "Licenciaturas",
    link: "/licenciaturas",
  },
  {
    id: "maestrias",
    title: "Maestrías",
    link: "/maestrias",
  },
  {
    id: "diplomados",
    title: "Diplomados",
    link: "/diplomados",
  },
  //{
  //  id: "certificaciones-INCAP",
  //  title: "Certificaciones INCAP",
  //  link: "/certificaciones",
  //},
  {
    id: "contacto",
    title: "Contacto",
    link: "/contacto",
  },
];