import React from "react"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-6 mt-10">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <div className="lg:col-span-6">
              <h3 className="text-lg font-semibold mb-4">Sobre Nosotros</h3>
              <p className="text-justify">
                El instituto de capacitación y aprendizaje permanente (INCAP),
                empresa educativa 100% mexicana, creada en el año 2017. <br />
                Tras su experiencia frente a grupo, un equipo de docentes tomó
                la decisión de desarrollar un proyecto de modelo educativo que
                permita combinar a los usuarios de la institución el aprendizaje
                en las aulas con el ámbito laboral.
              </p>
            </div>
            <div className="lg:col-span-3">
              <h3 className="text-lg font-semibold mb-4">Enlaces Útiles</h3>
              <ul className="list-none">
                <li className="mb-2">
                  <Link to="demo/contacto">Términos de Servicio</Link>
                </li>
                <li className="mb-2">
                <Link to="demo/contacto">Política de Privacidad</Link>
                </li>
                <li>
                <Link to="demo/contacto">FAQs</Link>
                </li>
              </ul>
            </div>
            <div className="lg:col-span-3">
              <h3 className="text-lg font-semibold mb-4">Contáctanos</h3>
              <p className="mb-2">
                Paseo de los Jazmines #303 L-4, Villa de Álvarez, Colima,
              </p>
              <p className="mb-2">Email: contacto@incap.edu.mx</p>
              <p>Teléfono: (312) 339 85 73</p>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer
