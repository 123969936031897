import React from "react";
import img from "../../assets/images/main/wa.png";

const WhatsAppBtn = () => {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=3123398573&text=Hola, deseo obtener más información sobre la escuela en línea."
      className="whatsapp-button fixed right-4 bottom-4 z-10"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={img}
        alt="whatsAppBtn"
      />
    </a>
  );
};

export default WhatsAppBtn;
