import React from "react";
import Card from "../components/Card";
import Heading from "../components/common/Heading.jsx";

const DiplomadosHome = () => {
  return (
    <>
      <div className="w-full overflow-hidden">
        <Heading subtitle="" title="Conoce Nuestros Diplomados" />
        <Card section={"diplomados"} />
      </div>
    </>
  );
};

export default DiplomadosHome;
