import React from "react";
import CardWithDetail from "../components/CardWithDetail";
import Heading from "../components/common/Heading.jsx";

const LicenciaturasHome = () => {
  return (
    <>
      <div className="w-full overflow-hidden footerAdjust">
        <Heading subtitle="" title="Conoce Nuestras Licenciaturas" />
        <CardWithDetail section={"licenciaturas"} />
      </div>
    </>
  );
};

export default LicenciaturasHome;
