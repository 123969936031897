import React from "react";
import Card from "../components/Card";
import Heading from "../components/common/Heading.jsx";

const MaestriasHome = () => {
  return (
    <>
      <div className="w-full overflow-hidden footerAdjust">
        <Heading subtitle="" title="Conoce Nuestras Maestrias" />
        <Card section={"maestrias"} />
      </div>
    </>
  );
};

export default MaestriasHome;
