import React, { useState } from 'react';
import { certificaciones } from "../constants/certificaciones.js";

const CertificacionesHome = () => {
  const [expandedCertId, setExpandedCertId] = useState(null);

  const handleCertClick = (certId) => {
    if (expandedCertId === certId) {
      setExpandedCertId(null);
    } else {
      setExpandedCertId(certId);
    }
  };

  return (
    <>
      <div className="w-full overflow-hidden footerAdjust">
        <section>
          <div className='certificacion'>
            {certificaciones.map(certificacion => (
              <div key={certificacion.id}>
                <h2 onClick={() => handleCertClick(certificacion.id)}>
                  {certificacion.name} / {certificacion.startDate} - {certificacion.endDate}
                </h2>
                {expandedCertId === certificacion.id && (
                  <ul>
                    {certificacion.people.map(persona => (
                      <li key={persona.id}>{persona.name}</li>
                    ))}
                  </ul>
                )}
                <br></br>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  )
}

export default CertificacionesHome;
