import React from "react";
import logo from "../assets/images/main/logo.png";

const Contact = () => {
  return (
    <>
      <section className="contacts p-5">
        <div className="mx-auto max-w-[85%] shadow-lg bg-white flex flex-col lg:flex-row justify-between">
          <div className="lg:w-1/2">
            <iframe
              className="w-full h-80 lg:h-full"
              src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=es&amp;q=P.%C2%BA%20de%20Jazmines%20303-L-4,%20Real%20Bugambilias,%2028984%20Cdad.%20de%20Villa%20de%20%C3%81lvarez,%20Col.+(incap)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              title="Map"
            ></iframe>
          </div>
          <div className="p-10">
            <div className="flex items-center">
              <img src={logo} alt="Logo" className="mr-4 h-[90px]" />
              <div>
                <h1 className="text-3xl font-semibold">Contáctanos</h1>
                <p className="text-gray-500">
                  Déjanos tus dudas o comentarios.
                </p>
              </div>
            </div>
            <div className="items mt-6">
              <div className="box">
                <h4 className="font-semibold">Ubicación:</h4>
                <p className="mb-2">
                  Paseo de los Jazmines #303 L-4, Villa de Álvarez, Colima,
                  México., C.P. 28984
                </p>
              </div>
              <div className="box">
                <h4 className="font-semibold">Correo electrónico:</h4>
                <p className="mb-2">contacto@incap.edu.mx</p>
              </div>
              <div className="box">
                <h4 className="font-semibold">Teléfono:</h4>
                <p>(312) 339 85 73</p>
              </div>
            </div>

            <form className="bg-white rounded-md mt-5">
              <h1 className="text-xl font-semibold mb-6">
                Escribenos tus dudas:
              </h1>

              <div className="grid grid-cols-1 gap-4 mb-4 lg:grid-cols-2">
                <input
                  className="p-3 border rounded-md outline-none"
                  type="text"
                  placeholder="Nombre"
                />
                <input
                  className="p-3 border rounded-md outline-none"
                  type="email"
                  placeholder="Correo electrónico"
                />
              </div>

              <input
                className="w-full p-3 border rounded-md outline-none mb-4"
                type="text"
                placeholder="Título"
              />
              <textarea
                className="w-full p-3 border rounded-md outline-none mb-4"
                rows="5"
                placeholder="Déjanos tus dudas"
              ></textarea>

              <button className="w-full py-3 bg-primary text-white rounded-md hover:bg-primary-dark transition duration-300">
                ENVIAR
              </button>
            </form>

            <h3 className="mt-8 font-semibold">
              Síguenos en nuestras redes sociales
            </h3>
            <div className="social">
              <a
                href="https://www.facebook.com/incapcolima/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f icon"></i>
              </a>
              <a
                href="https://www.instagram.com/incapcolima?igsh=ZnhkajZldXJmM2E="
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram icon"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
