export const homeAbout = [
  {
    id: "HA1",
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Flexibilidad de horario",
    desc: "Puedes acceder al material de estudio y completar las tareas en tu propio horario, lo que te permite adaptar tus estudios a tus necesidades.",
  },
  {
    id: "HA2",
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Acceso desde cualquier lugar",
    desc: "No estás limitado por la ubicación geográfica. Mientras tengas acceso a internet, puedes estudiar desde cualquier lugar del mundo.",
  },
  {
    id: "HA3",
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Adquisición de habilidades digitales",
    desc: "Estudiar en línea te familiariza con herramientas digitales y tecnologías de la información, lo que puede ser útil en el mundo laboral actual.",
  },
]

export const awrapper = [
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "100%",
    title: "En línea",
  },

  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "36",
    title: "Meses",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "Todas las",
    title: "Mensualidades Congeladas",
  },
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "Variedad de",
    title: "Diplomados",
  },
]

export const bachilleratoKeys = [
  {
    id:1,
    desc:"Desarrollas conocimientos, habilidades y competencias para aplicarlas en diferentes contextos, además cultivas aspectos intelectuales y emocionales que te preparan para diversas situaciones de la vida."
  },
  {
    id:2,
    desc:"Ambiente seguro, sano e incluyente que genera una comunidad y espacios positivos para que tengas un sólido crecimiento.",
  },
  {
    id:3,
    desc:"Infraestructura tecnológica. Biblioteca virtual en la que encontrarás la totalidad de los recursos bibliográficos que necesites. Además de contar con el respaldo de la plataforma educativa que INCAP tiene para ti, es confiable y se encuentra disponible las 24 hrs. los 365 días del año con apoyo técnico, administrativo, académico y socioemocional.",
  },
  {
    id:4,
    desc:"Orientación vocacional para descubrir tus intereses, aptitudes y elegir la carrera que te apasione.",
  },
  {
    id:5,
    desc:"La posibilidad de estudiar desde cualquier lugar con conexión a internet.",
  },
  {
    id:6,
    desc:"Con clases 100% en línea.",
  },
  {
    id:7,
    desc:"Con nuestro programa podrás terminar tu bachillerato en un menor tiempo. ",
  },
  {
    id:8,
    desc:"También te da la posibilidad de desarrollar tus habilidades de autoaprendizaje.",
  },
  {
    id:9,
    desc:"Al estudiar en modalidad online, aprenderás a fomentar tu iniciativa para profundizar en los temas de interés, obtendrás disciplina y constancia para concretar tus actividades escolares.",
  },
  {
    id:10,
    desc:"En la Prepa en Línea fortalecerás competencias que te servirán para trasladarte al nivel superior de estudios y para tener una inserción pronta y exitosa a escenarios laborales.",
  },
  {
    id:11,
    desc:"Con el dominio de la modalidad online fortalecerás tus conocimientos y destrezas para el uso de la tecnología: correo electrónico, procesadores de texto y navegar por internet. ",
  },
  {
    id:12,
    desc:"Fomentará tu gusto por la lectura y la escritura, así como tú habilidad para organizar el tiempo libre y de trabajo.",
  },
  {
    id:13,
    desc:"La Prepa en Línea te permite organizar tu tiempo para atender actividades personales o laborales y al mismo tiempo prepararte para tu futura vida universitaria.",
  }
]

//EXAMPLES
export const testimonal = [
  {
    id: 1,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    imageIcon: "testimonial_people/tp1.webp"
  },
  {
    id: 2,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    imageIcon: "testimonial_people/tp2.webp"
  },
  {
    id: 3,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    imageIcon: "testimonial_people/tp3.webp"
  },
]