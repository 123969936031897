import { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/main/logo.png";
import close from "../../assets/images/main/close.svg";
import menu from "../../assets/images/main/menu.svg";

import { navLinks } from "../../constants/navLinks.js";
import styles from "../../assets/styles/style.js";

const Header = () => {
  const [active, setActive] = useState("Inicio");
  const [toggle, setToggle] = useState(false);

  return (
    <div className="bg-blackOpacy w-full overflow-hidden">
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <nav className="w-full flex py-4 justify-between items-center navbar">
            <a href="/">
              <img
                src={logo}
                alt="hoobank"
                className="w-[60px] h-[70px] mb-[-20px] mt-[-20px]"
              />
            </a>

              <ul className="list-none sm:flex hidden justify-end items-center flex-1">
              {navLinks.map((nav, index) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-normal cursor-pointer text-[16px] ${
                    active === nav.title ? "text-white" : "text-dimWhite"
                  } ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}`}
                  onClick={() => {
                    setActive(nav.title);
                  }}
                >
                  <Link to={nav.link} className="block w-full">
                    {nav.title}
                  </Link>
                </li>
              ))}
            </ul>

            <div className="sm:hidden flex flex-1 justify-end items-center">
              <img
                src={toggle ? close : menu}
                alt="menu"
                className="w-[28px] h-[28px] object-contain"
                onClick={() => setToggle(!toggle)}
              />

              <div
                className={`${
                  !toggle ? "hidden" : "flex"
                } p-6 bg-gradient-to-b from-[#11152d] via-gray-800 to-[#11101d] absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar z-10`}
              >
                <ul className="list-none flex justify-end items-start flex-1 flex-col">
                  {navLinks.map((nav, index) => (
                    <li
                      key={nav.id}
                      className={`font-poppins font-medium cursor-pointer text-[16px] ${
                        active === nav.title ? "text-white" : "text-dimWhite"
                      } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                      onClick={() => setActive(nav.title)}
                    >
                      <Link to={nav.link} className="block w-full">
                        {nav.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
