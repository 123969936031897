export const academicDegrees = [
  {
    id: 1,
    type: "Licenciatura",
    section: "licenciaturas",
    name: "Licenciatura en Derecho",
    nameInPage: "Licenciatura en Derecho",
    specialty: "Derecho",
    sepCode: "06PSU0066P",
    rvoe: "SE-008-2023-ES",
    rvoeLink:"https://drive.google.com/file/d/1vxYwPX954x3og3cPkcOPH4SDC-rWQbyF/view?usp=drive_link",
    videoLink:"https://www.youtube.com/embed/ibqAcp2BN0A",
    duration: "36 meses",
    modules: "8",
    imageIcon: "icons/i12.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "Aprende la correcta aplicación de las leyes y el marco jurídico mexicano. Siendo el Licenciado en Derecho el profesional preparado para preservar el Estado de Derecho con la correcta interpretación y aplicación de la Ley.",
    objetive: "Formar profesionales con sólidas bases teórico, prácticas y éticas, que sean responsables en el tema de los derechos humanos, la igualdad de género, con profundo compromiso social,  teniendo presente la dignidad de la persona humana y los derechos de las personas vulnerables, que ofrezca soluciones viables a los diferentes sectores de la sociedad, que sea empático con la problemática de nuestro país que trabaje por la conservación del medio ambiente, tolerante a la multiculturalidad y sensible a los problemas a nivel  local, regional, nacional e internacional, que trabaje en pro de la legalidad con apego a las prerrogativas fundamentales, la solución pacífica de conflictos y del Estado Democrático de Derecho con visión inter, multi y transdisciplinaria que además utilicen las tecnologías de la información y comunicaciones con experticia para potencializar sus capacidades.",
    question: "¿Por qué estudiar la Licenciatura en derecho?",
    why: {
      inline: "Al estudiar con nosotros aprenderás:",
      highlights:[
        "Formación cultural: estudiar Derecho no solo te permitirá conocer las leyes y normas que rigen la sociedad, sino que además te brindará una importante formación cultural, en la que aprenderás sobre diferentes disciplinas académicas, prácticas sociales, costumbres, tradiciones y ética.",
        "Seguridad económica: por su importancia a nivel social, esta es una carrera que ofrece una buena seguridad económica. Los profesionales en Derecho perciben buenos salarios y siempre serán demandados por empresas, o personas, dado que mucho de lo que hacemos requiere de normas, contratos y certificados que lo validen.",
        "Aprenderás a expresarte con excelencia: muchas personas sostienen que las carreras en el área de Derecho, como la abogacía, sirven para hacer del diálogo un arte. Podrás aprender a argumentar, expresarte con claridad y utilizar el lenguaje como la mayor arma de tu profesión.",
        "Amplio abanico de desarrollo profesional: las carreras en Derecho ofrecen una gran variedad de posibilidades de desarrollo profesional, pudiendo trabajar tanto en el ámbito privado, como el público. Los profesionales del área podrán emplearse en Consultorías, Firmas de Abogados, como también en Ministerios y centros de justicia.",
        "Podrás marcar una diferencia: en un mundo donde la injusticia es cada vez más común, los profesionales en Derecho tienen la posibilidad de marcar una diferencia y trabajar a favor de la verdad y la justicia, buscando lo mejor para sus clientes y también para la sociedad en su conjunto."
      ]
    },
    admissionProfile: {
      inline: "El aspirante de la Licenciatura en Derecho deberá tener vocación social y humanista, respeto de la problemática social que se vive en el país e interés por el bien común, con habilidad de comprensión lectora, razonamiento lógico, capacidad analítica y de persuasión. Manejo de Tecnologías de la información y comunicación, lectura y comprensión., así como ser capaz de administrar su proceso de aprendizaje.",
      highlights:[]
    },
    graduationProfile: {
      inline: "El Instituto de Capacitación y Aprendizaje Permanente tiene como principal objetivo formar profesionales competentes e informados, dotados de sentido social y conciencia nacional, para participar en las transformaciones requeridas para el mejoramiento del bienestar de los mexicanos. Así pues, el egresado de la licenciatura de Derecho del Instituto de Capacitación y Aprendizaje Permanente será un profesional formado con valores, resaltando un espíritu solidario con las causas de justicia y equidad. Sera un profesional con habilidades y competencias para resolver problemas jurídicos con eficiencia, eficacia y oportunidad. Interiorizado con los valores fundamentales del derecho: justicia, libertad y seguridad jurídica.",
      highlights:[]
    }
  },
  {
    id: 2,
    type: "Licenciatura",
    section: "licenciaturas",
    name: "Licenciatura en Administración de Empresas",
    nameInPage: "Licenciatura en Administración de Empresas",
    specialty: "Administración de Empresas",
    sepCode: "06PSU0066P",
    rvoe: "SE-009-2023-ES",
    rvoeLink:"https://drive.google.com/file/d/1P8B-cUKpWns71wB2s_Y_01KeBTk75Anx/view?usp=drive_link",
    videoLink:"https://www.youtube.com/embed/2bvNcGGvOZ0",
    duration: "36 meses",
    modules: "8",
    imageIcon: "icons/i8.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "Como estudiante de la Licenciatura en Administración de Empresas aprenderás a identificar oportunidades de negocio e irás conociendo y comprendiendo el entorno global de las empresas.\n\nEn esta carrera, también aprovecharás recursos como la tecnología y la información para poder lograr las metas que beneficien tanto a las organizaciones como a las personas.",
    objetive: "Formar Licenciados en Administración con visión estratégica, ética y de compromiso social, capaces de promover el logro eficiente de objetivos de organizaciones públicas, privadas y sociales en sus diferentes niveles jerárquicos, a través del conocimiento y la comprensión de las funciones de las organizaciones, así como de su funcionamiento integral, así como el diagnóstico de los problemas administrativos específicos y su correspondiente propuesta e implantación de soluciones innovadoras.",
    question: "¿Por qué estudiar la Licenciatura en administración de empresas?",
    why: {
      inline: "Con la licenciatura en administración de empresas tendrás y desarrollarás:",
      highlights:[
        "Más oportunidades de estudio",
        "Estudios orientados a la práctica",
        "Oportunidades de trayectoria profesional",
        "Habilidades de liderazgo",
        "Habilidades de comunicación",
        "Habilidades de trabajo en red",
        "Salario",
        "Trabajo en equipo",
        "Mayor empleabilidad",
        "Trabajo por cuenta propia",
        "Creatividad"
      ]
    },
    admissionProfile: {
      inline: "Es deseable que los aspirantes dominen los conocimientos básicos del área de ciencias sociales y nociones del campo humanístico. Poniendo especial énfasis en el dominio de conocimientos lógicos y matemáticos básicos, las técnicas de investigación documental y el manejo de recursos informáticos básicos que le permitan emprender las actividades de aprendizaje necesarias en los diferentes cursos, así como el manejo de inglés e internet para acceder a fuentes primarias de información. La disposición para trabajar en equipo de manera coordinada y responsable, así como el manejo de códigos de comunicación verbal, escrita, actitud de servicio y de tolerancia, unida a la capacidad para la negociación y conciliación. \nDisposición para trabajar y adaptarse a situaciones cambiantes derivadas de situaciones imprevistas y una preocupación para lograr un desarrollo económico sustentable.",
      highlights:[]
    },
    graduationProfile: {
      inline: "El Instituto de Capacitación y Aprendizaje Permanente tiene como principal objetivo formar profesionales informados y con las competencias necesarias y exigidas en nuestra a actualidad, dotados de sentido social y conciencia nacional, para participar en las transformaciones requeridas para el mejoramiento del bienestar de los mexicanos. Así pues, el egresado de la licenciatura en Administración debe poseer los conocimientos que le permitan: ",
      highlights:[
        "Definir y establecer objetivos de diferentes niveles y funciones organizacionales, así como formular y aplicar estrategias, tácticas, políticas, presupuestos y programas para alcanzarlos eficientemente.",
        "Formular y evaluar cursos alternativos de acción y tomar decisiones sustentadas en ello, aplicando, cuando sea pertinente, métodos y técnicas de investigación.",
        "Dirigir a otros hacia el logro de objetivos organizacionales mediante la delegación, la comunicación, la motivación, la orientación, la supervisión, la preservación de la disciplina, la solución de conflictos interpersonales, el liderazgo, la negociación y la administración del cambio.",
        "Evaluar el logro de todo tipo de objetivos y de planes organizacionales y tomar las medidas correctivas pertinentes.",
        "Llevar a cabo negociaciones exitosas de diversa índole que contribuyan al logro de objetivos organizacionales.",
        "Aplicar técnicas de administración de recursos humanos, mercadotecnia, finanzas, operaciones y adquisiciones para el logro de objetivos organizacionales.",
        "Promover y administrar la calidad y la innovación tecnológica."
      ]
    }
  },
  {
    id: 3,
    type: "Licenciatura",
    section: "licenciaturas",
    name: "Licenciatura en Desarrollo y Gestión Organizacional",
    nameInPage: "Licenciatura en Desarrollo y Gestión Organizacional",
    specialty: "Desarrollo y Gestión Organizacional",
    sepCode: "06PSU0066P",
    rvoe: "SE-023-2023-ES",
    rvoeLink:"https://drive.google.com/file/d/1H722SQGyx9EKcJVpLZLANifN-P15pZIG/view?usp=drive_link",
    videoLink:"https://www.youtube.com/embed/sg9MzZ5jXDg",
    duration: "36 meses",
    modules: "8",
    imageIcon: "icons/i9.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "Con la Licenciatura Desarrollo y Gestión Organizacional, podrás identificar y solucionar problemas de relaciones laborales y el comportamiento humano, diseñar y llevar a cabo programas de capacitación y desarrollo del personal, y hacer evaluaciones del personal. Si te apasiona el Desarrollo, la Gestión Organizacional y el mundo empresarial, ¡tal vez sea la carrera para ti!",
    objetive: "El objetivo de la Licenciatura en Desarrollo y gestión organizacional es el de apoyar a todas las empresas a emplear los recursos totales de la organización para incrementar al máximo su competitividad, realizando las transformaciones requeridas, tanto en su estructura, como en la productividad y actitud del personal.",
    question: "¿Por qué estudiar la Licenciatura en desarrollo y gestión organizacional?",
    why: {
      inline: "Con esta licenciatura:",
      highlights:[
        "Aprenderás a desarrollar talento humano conociendo los procesos necesarios para descubrir y capacitar los perfiles de liderazgo en la organización. Serás capaz de llevar a cabo los procesos de coaching individual que impacten directamente a los indicadores de competencia.",
        "Lograrás cambios favorables en las organizaciones, motivando siempre al personal y desarrollando una cultura de efectividad. Además, aprenderás a promover trabajos grupales que permitan la integración y el trabajo en equipo, de esta manera te convertirás en un experto en cultura organizacional."
      ]
    },
    admissionProfile: {
      inline: "Como aspirante a estudiar este programa, deberás tener...",
      highlights:[
        "Capacidad para desarrollar pensamiento crítico y constructivo.",
        "Sensibilidad a las necesidades psicosociales de los demás.",
        "Interés por la comprensión del comportamiento humano en diversas situaciones y contextos.",
        "Disposición por desarrollar habilidades profesionales de comunicación y escucha.",
        "Disposición para el conocimiento personal y el desarrollo humano."
      ]
    },
    graduationProfile: {
      inline: "El Instituto de Capacitación y Aprendizaje Permanente tiene como principal objetivo formar profesionales informados y con las competencias necesarias y exigidas en nuestra a actualidad, así pues, el egresado de la Licenciatura en Desarrollo y Gestión Organizacional al finalizar sus estudios, habrá demostrado haber adquirido:",
      highlights:[
        "Bases filosóficas de las ciencias administrativas y sociales.",
        "Modelos de desarrollo del capital humano en las organizaciones.",
        "Fundamentos de la gestión organizacional.",
        "Bases teóricas de liderazgo, comunicación y motivación.",
        "Procesos psicológicos que intervienen en el comportamiento de los individuos y grupos dentro de las organizaciones.",
        "Modelos de desarrollo organizacional.",
        "Principios de matemáticas, contabilidad y finanzas.",
        "Bases de la psicometría aplicada al ámbito organizacional.",
        "Marco legal de las organizaciones.",
        "Sistemas de información y tecnología aplicados a las organizaciones.",
        "Fundamentos para la toma de decisiones."
      ]
    }
  },
  {
    id: 4,
    type: "Licenciatura",
    section: "licenciaturas",
    name: "Licenciatura en Ingeniería en Informática",
    nameInPage: "Licenciatura en Ingeniería en Informática - Próxima apertura",
    specialty: "Ingeniería en Informática",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "36 meses",
    modules: "8",
    imageIcon: "icons/i15.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "En la actualidad, las mayores compañías del mundo pertenecen al sector tecnológico. Es imposible no conocer Facebook, Microsoft, Amazon, Google, Netflix o Apple. Estas empresas son auténticos gigantes que ofrecen servicios vitales a cientos de millones de personas. Así, sin ellas no sería posible comunicarse con familiares y amigos, buscar todo tipo de información valiosa, acceder a contenidos audiovisuales de gran nivel o comprar productos cómodamente a través de internet.",
    objetive: "La Licenciatura en Ingeniería en Informática de INCAP ofrece a los alumnos la posibilidad de acceder a las mejores oportunidades profesionales, gracias a sus contenidos especializados y profundos, y al enfoque práctico de su enseñanza. Esto permite a los estudiantes obtener competencias y habilidades clave que podrán aplicar en sus carreras de forma inmediata. Así, esta titulación les convertirá en profesionales muy codiciados por las grandes empresas tecnológicas del mundo, gracias a la gran cantidad de capacitaciones con las que contarán, cuestión que les permitirá desenvolverse en cualquier puesto de esta industria.",
    question: "¿Por qué estudiar la Licenciatura en ingeniería en informática?",
    why: {
      inline: "En la actualidad México necesita ingenieros informáticos que sean capaces de dominar diferentes cuestiones de la disciplina, desde aspectos profundos sobre matemáticas y física, pasando por la programación avanzada, los sistemas operativos o la ingeniería de software, hasta el dominio de bases de datos y algoritmos. Todo ello para que puedan convertirse en auténticos expertos en cuestiones como el cloud computing, el diseño de videojuegos, la inteligencia artificial, la tecnología blockchain, la minería de datos o el internet de las cosas.",
      highlights:[
      ]
    },
    admissionProfile: {
      inline: "",
      highlights:[
      ]
    },
    graduationProfile: {
      inline: "Esta Licenciatura en Ingeniería en Informática está especialmente diseñada para que sus alumnos puedan acceder al mercado laboral de la industria tecnológica con todas las garantías. Para ello, ofrece contenidos de alto nivel y desarrolla competencias totalmente enfocadas a la actualidad de la profesión, haciendo que los estudiantes puedan acceder a una enorme variedad de puestos en este sector una vez finalicen la titulación. Así, el enfoque de INCAP está dirigido a que sus alumnos alcancen sus objetivos vitales y profesionales, por lo que este programa es todo lo que buscan para convertirse en grandes ingenieros informáticos.",
      highlights:[
      ]
    }
  },
  {
    id: 5,
    type: "Maestría",
    section: "maestrias",
    name: "Maestría en Educación y Competencias Digitales",
    nameInPage: "Maestría en Educación y Competencias Digitales",
    sepCode: "",
    rvoe: "SE-006-2024-ES",
    rvoeLink:"https://drive.google.com/file/d/1f_KlUGlTSlN7xZuJkRqFNTHWYncWtoQw/view?usp=drive_link",
    videoLink:"https://www.youtube.com/embed/dIg9hDwWEzM",
    duration: "20 meses",
    modules: "4",
    imageIcon: "icons/i3.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "El Instituto de Capacitación y Aprendizaje Permanente (INCAP) se plantea como propósito responder a las necesidades actuales de formación en materia de educación, las cuales requieren de una orientación profesionalizante, con el objetivo de formar actores que desarrollen nuevas habilidades y competencias, necesarias ante el nuevo contexto educativo. Considerando el papel preponderante de la tecnología en nuestras vidas y que la educación no es ajena a dicho avance, se debe tener en cuenta que la docencia es una disciplina que también progresa al ritmo de los tiempos, para aportar al alumnado todas las claves de la comunicación social actual.",
    objetive: "Formar Maestros con alto sentido crítico que sean capaces de dar respuesta a las problemáticas actuales mostrando diferentes opciones y formas de trabajo, mediante el conocimiento de nuevas herramientas y tecnologías aplicadas a la docencia, dotados de habilidades, destrezas y competencias digitales. Así como explorar a profundidad las competencias digitales indispensables en la actualidad, para un óptimo desempeño en el ejercicio de su profesión.",
    question: "¿Por qué estudiar la Maestría en educación y competencias digitales?",
    why: {
      inline: "Destacarás del resto al estar capacitado para crear estrategias pedagógicas, adaptadas a la nueva realidad educativa, en la que los alumnos te demandan una nueva forma de enseñar y una nueva forma de aprender. \nCon la Maestría en Educación y Competencias Digitales, podrás potenciar tu perfil profesional en 18 meses, aprendiendo las principales habilidades en innovación educativa:",
      highlights:[
        "Aplicar dentro del aula una red de conocimiento compartido, adaptando su contenido a los intereses y capacidades de los alumnos.",
        "Elaborar un diseño pedagógico para la educación en entornos donde se utilizan tecnologías digitales.",
        "Crear contenidos educativos digitales.",
        "Diseñar, aplicar y evaluar herramientas para la creación y gestión de redes sociales en el ámbito de la tecnología educativa y competencias digitales."
      ]
    },
    admissionProfile: {
      inline: "El aspirante de la Maestría en Educación y Competencias Digitales requiere tener concluida una carrera profesional, relacionada con las áreas de educación, de humanidades o ciencias sociales, de administración o afines, que cuente con habilidad de comprensión lectora, razonamiento lógico, capacidad analítica y de persuasión, manejo y comprensión de Tecnologías de la Información y Comunicación, así como ser capaz de administrar su proceso de aprendizaje.",
      highlights:[]
    },
    graduationProfile: {
      inline: "Formar profesionales competentes e informados, dotados de sentido social y conciencia nacional, para participar en las transformaciones requeridas para el mejoramiento del bienestar de los mexicanos. Así pues, el egresado de la Maestría en Educación y Competencias Digitales del Instituto de Capacitación y Aprendizaje Permanente será un profesional formado con valores, resaltando un espíritu solidario y con la capacidad de desempeñarse en ámbitos de la actividad educativa y pedagógica, de la investigación educativa, de la docencia y la gestión, para ponerlos en práctica de la mano de las competencias digitales.",
      highlights:[]
    }
  },
  {
    id: 6,
    type: "Maestría",
    section: "maestrias",
    name: "Maestría en Juicios Orales",
    nameInPage: "Maestría en Juicios Orales",
    sepCode: "",
    rvoe: "SE-065-2023-ES",
    rvoeLink:"https://drive.google.com/file/d/1MzKRQgo5pZA3-hzafRrNcaOhBgSCKgEd/view?usp=drive_link",
    videoLink:"https://www.youtube.com/embed/vfB0O4-XXAY",
    duration: "20 meses",
    modules: "4",
    imageIcon: "icons/i7.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "Es importante resaltar que, en el proceso predominantemente oral, resulta de gran relevancia que los actores que intervienen en él, posean un amplio sentido crítico, y no solamente un acervo teórico, para la resolución de hechos concretos jurídicamente hablando.\n\nEste plan de estudios con una orientación profesionalizante favorece la formación de Maestros que desarrollen prácticas éticas y que se responsabilicen en el respeto y la defensa de los Derechos Humanos y la igualdad de género, con profundo compromiso social, protectores de la dignidad de la persona humana y de los derechos de las personas en situación de vulnerabilidad.",
    objetive: "Formar y actualizar profesionales del Derecho con alta capacidad y con amplios conocimientos y habilidades, para que sean capaces de fundamentar estrategias de defensa de sus representados y actúen con principios éticos y compromisos que favorezcan el desarrollo humano, económico y social del país desde este nuevo sistema de impartición de justicia, predominantemente oral.",
    question: "¿Por qué estudiar la Maestría en juicios orales?",
    why: {
      inline: "Con la Maestría en Juicios Orales aprenderás lo último en técnicas de litigación oral y adquirirás las habilidades necesarias para enfrentarte con éxito a un proceso judicial oral. \nProfundizarás en elementos clave como la oratoria, la negociación, la técnica del interrogatorio y alegatos. Estudia la Maestría en Juicios Orales y conviértete en un experto en:",
      highlights:[
        "Instrumentar estrategias de defensa que permitan a las personas físicas y morales enfrentar problemas de tipo legal.",
        "Manejar técnicas y herramientas en el debate oral para el desarrollo en los juicios orales.",
        "Aplicar los principales instrumentos de litigación en el proceso de audiencias de los procesos de impartición de justicia en México.",
        "Manejar diferentes fuentes del derecho y tener la capacidad de analizar y sintetizar la información."
      ]
    },
    admissionProfile: {
      inline: "",
      highlights:[]
    },
    graduationProfile: {
      inline: "",
      highlights:[]
    }
  },
  {
    id: 7,
    type: "Maestría",
    section: "maestrias",
    name: "Maestría en Inteligencia Artificial Aplicada",
    nameInPage: "Maestría en Inteligencia Artificial Aplicada - Próxima apertura",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "20 meses",
    modules: "4",
    imageIcon: "icons/i4.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "La Maestría en Inteligencia Artificial Aplicada es una alternativa educativa que surge como una respuesta a la necesidad de las empresas locales, nacionales e internacionales de contar con profesionistas de posgrado especializados en las áreas de ciencia de datos, software inteligente, movilidad autónoma y estrategia de negocios. Todas ellas enmarcadas con un componente sólido de IA que enriquece las soluciones de sus problemáticas, ayudando en su transición a consolidarse como una Industria 4.0 y en apoyo a la construcción de una ciudad inteligente.",
    objetive: "Formar Maestros en Inteligencia Artificial Aplicada que sean un agente de cambio innovador, con un perfil integral y con conocimientos tecnológicos emergentes de Interacción Humano-computadora y tecnología Interactiva,  que estén calificados y sea capaces, para desempeñarse y realizar desarrollo tecnológico en el área computacional con el fin de apoyar a la resolución de problemas de cómputo desde la perspectiva del usuario del sector productivo, industrial y de servicios públicos y/o privados en el ámbito nacional e internacional mediante soluciones basadas en inteligencia artificial y tecnologías emergentes.",
    question: "¿Por qué estudiar la Maestría en inteligencia artificial aplicada?",
    why: {
      inline: "",
      highlights:[
        "Por la demanda constante en el mercado laboral",
        "Por la solución de problemas del mundo real",
        "Por las aplicaciones diversas en todas las industrias",
        "Por la participación en proyectos innovadores",
        "Por los avances en investigación científica",
        "Por el desarrollo de habilidades analíticas y de resolución de problemas"
      ]
    },
    admissionProfile: {
      inline: "El aspirante de la Maestría en Inteligencia Artificial Aplicada requiere tener concluida una carrera profesional, de preferencia relacionada con las áreas afines, tales como automatización, mecatrónica, computación, informática, sistemas computacionales, biomédica, matemáticas, nanotecnología, eléctrica, electrónica, telecomunicaciones, que cuente con la competencia de plantear y formular estrategias para la solución de problemas, así como ser capaz de administrar su proceso de aprendizaje.",
      highlights:[]
    },
    graduationProfile: {
      inline: "El Instituto de Capacitación y Aprendizaje Permanente tiene como principal objetivo formar profesionales competentes e informados, dotados de sentido social y conciencia nacional, para participar en las transformaciones requeridas para el mejoramiento del bienestar de los mexicanos. Así pues, el egresado de la Maestría en Inteligencia Artificial Aplicada del Instituto de Capacitación y Aprendizaje Permanente será un profesional formado con valores, resaltando un espíritu solidario y el compromiso de estar actualizado en conocimientos científicos y tecnológicos de ciencias de la computación.",
      highlights:[]
    }
  },
  {
    id: 8,
    type: "Maestría",
    section: "maestrias",
    name: "Maestría en Alta Dirección y Estrategia Empresarial",
    nameInPage: "Maestría en Alta Dirección y Estrategia Empresarial - Próxima apertura",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "20 meses",
    modules: "4",
    imageIcon: "icons/i9.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "El instituto de Capacitación y Aprendizaje Permanente se plantea formar maestros capaces de desempeñarse como directivos o consultores de alto nivel, participando en los procesos de toma de decisiones y de formulación e implantación de estrategias para la creación, organización, dirección y desarrollo de empresas, aplicando para ello habilidades de negociación y liderazgo, así como los métodos cuantitativos pertinentes, en un marco de ética y responsabilidad social.",
    objetive: "Formar Maestros en Alta Dirección especializados y calificados para desarrollar asesoría individual o empresarial, gestión y dirección de negocios, mediante el dominio de los procesos administrativos para que sean capaces de desempeñarse en puestos de alta dirección y que tengan como base la responsabilidad social para la mejora de los niveles de innovación, competitividad en la organización y vinculación con los sectores productivos y agentes económicos.",
    question: "¿Por qué estudiar la Maestría en inteligencia artificial aplicada?",
    why: {
      inline: "",
      highlights:[
        "Una formación muy completa y orientada al liderazgo",
        "Tendrás una visión global del negocio",
        "Desarrollarás el pensamiento crítico, estratégico y la ética empresarial",
        "Un profesional orientado al cambio",
        "Por sus múltiples salidas laborales",
        "Adquirirás grandes dotes comunicativas"
      ]
    },
    admissionProfile: {
      inline: "El aspirante de la Maestría en Alta Dirección y Estrategia Empresarial requiere tener concluida una carrera profesional, de preferencia relacionada con las áreas económico - administrativas, que cuente con habilidad de comprensión lectora, razonamiento lógico, capacidad analítica y de persuasión, manejo y comprensión de Tecnologías de la Información y Comunicación (TIC´s), así como ser capaz de administrar su proceso de aprendizaje.",
      highlights:[]
    },
    graduationProfile: {
      inline: "El Instituto de Capacitación y Aprendizaje Permanente tiene como principal objetivo formar profesionales competentes e informados, dotados de sentido social y conciencia nacional, para participar en las transformaciones requeridas para el mejoramiento del bienestar de los mexicanos. Así pues, el egresado de la Maestría en Maestría en Alta Dirección y Estrategia Empresarial del Instituto de Capacitación y Aprendizaje Permanente será un profesional formado con valores, resaltando un espíritu solidario y con la capacidad analizar e interpretar la información generada por las áreas funcionales utilizando los sistemas de información administrativa.",
      highlights:[]
    }
  },
  {
    id: 9,
    type: "Diplomado",
    section: "diplomados",
    name: "Nuevo código procesal civil y familiar mexicano",
    nameInPage: "Diplomado en Nuevo código procesal civil y familiar mexicano",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "5 meses",
    modules: "5",
    imageIcon: "icons/i7.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "",
    objetive: "",
    question: "",
    why: { inline:"", highlights:[
        "El nuevo Código Nacional de Procedimientos Civiles y Familiares tiene como objetivo establecer procedimientos homologados en todo el país para dirimir las controversias entre particulares.",
        "Este nuevo código impacta directamente en la vida de las personas debido a que las materias civil y familiar están relacionadas con asuntos comunes y cotidianos y tiene el potencial de ser un instrumento legal de pacificación social que evite que un conflicto se convierta en un hecho violento.",
        "Esta propuesta busca priorizar la oralidad y un efectivo acceso a la justicia pronta, completa, imparcial y expedita, e integra un modelo para impulsar el uso de las tecnologías de la información en los procesos judiciales.",
        "Es una tendencia evidente hacia la oralidad, la justicia digital, el respeto a los derechos humanos y la protección de aquellas personas que se encuentren en alguna situación de vulnerabilidad.",
        "Otro cambio muy significativo es el reconocimiento de los derechos de los niños en un proceso de divorcio. Los cambios buscan proteger los intereses de los niños en todo momento, garantizando su bienestar, sus derechos a la convivencia y el cuidado, y que su voz sea escuchada en procedimientos legales.",
        "Todos los procedimientos regulados en el Código Nacional de Procedimientos Civiles y Familiares podrán tramitarse bajo la modalidad de procedimiento en línea que, al igual que cualquier otra modalidad procesal, será gratuita para las partes."
      ]},
    admissionProfile: { inline:"", highlights:[]},
    graduationProfile: { inline:"", highlights:[]},
  },
  {
    id: 9,
    type: "Diplomado",
    section: "diplomados",
    name: "Derecho Procesal Laboral (Nuevo Proceso)",
    nameInPage: "Diplomado en Derecho Procesal Laboral (Nuevo Proceso)",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "5 meses",
    modules: "5",
    imageIcon: "icons/i12.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "",
    objetive: "",
    question: "",
    why: { inline:"", highlights:[
        "La reforma al artículo 123, apartado A de la Constitución, de 2017 y de la ley en mayo de 2019, abrió un espacio para la reconfiguración del sistema de justicia laboral.",
        "No se trató únicamente de la extinción de las Juntas de Conciliación y Arbitraje (JCyA ) y la consecuente creación de los tribunales laborales del Poder Judicial de la Federación o de las entidades federativas; más bien debe concebirse como el momento preciso para establecer un sistema de juicios que haga efectivo el acceso a la justicia, basado en los principios de inmediación, concentración, publicidad, celeridad y gratuidad, a través de la técnica oral.",
        "Entenderemos por Derecho Procesal Laboral, a aquellas características o conjunto de reglas que deben contener y guardar los procesos laborales, desde la etapa de inicio hasta su conclusión; es decir, orientan cada una de las etapas procesales a seguir, con determinadas características.",
        "El participante comprenderá los elementos básicos que debe regir cualquier proceso laboral, las características y consecuencias de los conflictos de trabajo, la importancia y trascendencia de estos en cualquier organización de trabajo, con la finalidad de obtener las herramientas en materia de proceso laboral."
      ]},
    admissionProfile: { inline:"", highlights:[]},
    graduationProfile: { inline:"", highlights:[]}
  },
  {
    id: 10,
    type: "Diplomado",
    section: "diplomados",
    name: "Derecho Corporativo y Empresarial",
    nameInPage: "Diplomado en Derecho Corporativo y Empresarial",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "5 meses",
    modules: "5",
    imageIcon: "icons/i8.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "",
    objetive: "",
    question: "",
    why: { inline:"", highlights:[
        "Tener una noción sobre Derecho Corporativo y Empresarial te da elementos necesarios para construir tu empresa, con las obligaciones y responsabilidades fiscales bien planteadas. Evitando que tu organización incurra en problemas de índole legal.",
        "En principio es importante destacar la diferencia entre el derecho empresarial y el derecho corporativo, ya que, si bien tienen puntos de convergencia, se pueden separar en su estudio. Empezaremos por decir que el derecho empresarial regula y estudia a todas las empresas sin importar su tamaño o importancia (pequeñas y medianas empresas conocidas como PyMES, grandes empresas o corporaciones), mientras que el derecho corporativo estudia sólo a las grandes empresas o corporaciones.",
        "Debido a ello es indispensable que los especialistas en derecho, administración de empresas, contadores y en general todos aquellos que formen parte de una gran empresa o corporación, o estén planeando formar una, tengan los conocimientos y habilidades necesarias para asesorar jurídicamente a las mismas o simplemente tomar las mejores determinaciones.",
        "En el Diplomado en Derecho Corporativo y Empresarial dominarás habilidades relacionadas con el gobierno de sociedades hasta las estrategias legales para fusiones y adquisiciones, propiedad intelectual, obligaciones fiscales y laborales, conociendo los aspectos clave del entorno empresarial desde una perspectiva legal."
      ]},
    admissionProfile: { inline:"", highlights:[]},
    graduationProfile: { inline:"", highlights:[]},
  },
  {
    id: 11,
    type: "Diplomado",
    section: "diplomados",
    name: "Derecho Procesal Penal",
    nameInPage: "Diplomado en Derecho Procesal Penal",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "5 meses",
    modules: "5",
    imageIcon: "icons/i7.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "",
    objetive: "",
    question: "",
    why: { inline:"", highlights:[
        "Artículo 20, apartado A, fracción I, de la Constitución Política de los Estados Unidos Mexicanos (CPEUM):",
        "“... El proceso penal tendrá por objeto el esclarecimiento de los hechos, proteger al inocente, procurar que el culpable no quede impune y que los daños causados por el delito se reparen”.",
        "El derecho penal es el sistema de normas jurídicas que regulan la potestad punitiva del Estado, asociando a hechos, estrictamente determinados por la ley, como presupuesto, una pena, medida de seguridad o corrección como consecuencia, con el objetivo de asegurar los valores elementales sobre los cuales descansa la convivencia humana pacífica (Enrique Cury).",
        "El identificar los elementos normativos y subjetivos que se encuentran inmersos en la conducta delictiva, a través de los conceptos de antijuridicidad, culpabilidad, imputabilidad, punibilidad y tipicidad, así como la aplicación de sanciones a través de la pena y la reparación del daño; todos ellos, como componentes primordiales del Derecho Penal.",
        "La implementación de un sistema acusatorio de corte adversarial, este sistema de justicia penal busca mejorar la forma de impartición y procuración de justicia, así como fortalecer el respeto a los derechos humanos, tanto de víctimas u ofendidos como de imputados, garantizar el acceso a la justicia, el rápido esclarecimiento de los hechos, mayor transparencia en el actuar de los operadores del esquema de justicia penal y, por supuesto, generar más medios alternativos para poner fin al conflicto penal, entre otros.",
        "Con la reforma constitucional de seguridad y justicia del año 2008, y la de la incorporación de los derechos humanos a nuestro ordenamiento supremo en 2011, la implementación del nuevo esquema de justicia penal es ahora una obligación para las entidades federativas."
      ]},
    admissionProfile: { inline:"", highlights:[]},
    graduationProfile: { inline:"", highlights:[]},
  },
  {
    id: 12,
    type: "Diplomado",
    section: "diplomados",
    name: "Herramientas y recursos docentes para la enseñanza y aprendizaje",
    nameInPage:
        "Diplomado en Herramientas y recursos docentes para la enseñanza y aprendizaje",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "5 meses",
    modules: "5",
    imageIcon: "icons/i7.png",
    fullPayment: "$9,999",
    monthlyPayment: "$ 999",
    introductionToTheProgram: "",
    objetive: "",
    question: "",
    why: { inline:"", highlights:[
        "Ante la realidad cambiante que estamos viviendo y considerando que el ser humano es un ser social y creador, queda clara la necesidad de que la educación tome nuevos rumbos y aunado a lo anterior los docentes y los psicólogos han comprendido la necesidad de que la educación, la creatividad y las herramientas digitales vayan de la mano considerando que el potencial del pensamiento creativo es una poderosa herramienta didáctica indispensable, así pues favorece el proceso de enseñanza-aprendizaje.",
        "Existen recursos docentes que permiten mejorar los procesos de enseñanza y aprendizaje en las instituciones educativas. Además, constantemente surgen nuevas herramientas que favorecen el sector educativo.",
        "Para poder aplicar estas nuevas herramientas en su trabajo, es necesario que los profesionales continúen con su preparación en esta área. Con este programa te ofrecemos una capacitación de calidad y 100% online que te ayudará a lograr el éxito.",
        "Un ejemplo de esto, es el arteterapia aplicada en el marco escolar, se revela como una herramienta de apoyo emocional al aprendizaje. Es una realidad que las dificultades emocionales afectan el proceso de aprendizaje y se reflejan en el rendimiento escolar, independientemente de las capacidades intelectuales del individuo y de acuerdo con Dalley (1960) en las escuelas que se ha establecido el arteterapia se ha podido ver como beneficia en el proceso de aprendizaje así como a niños, niñas y adolescentes que tienen dificultades emocionales o de comportamiento y se logra atender aquellas necesidades especiales que\n" +
        "anteriormente no se podían tratar."
      ]},
    admissionProfile: { inline:"", highlights:[]},
    graduationProfile: { inline:"", highlights:[]},
  },
  {
    id: 13,
    type: "Diplomado",
    section: "diplomados",
    name: "Técnicas de intervención en crisis suicidas",
    nameInPage: "Diplomado en Técnicas de intervención en crisis suicidas",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "5 meses",
    modules: "5",
    imageIcon: "icons/i10.png",
    fullPayment: "$9,999",
    monthlyPayment: "$ 999",
    introductionToTheProgram: "",
    objetive: "",
    question: "",
    why: { inline:"", highlights:[
        "Especialización y actualización en modelos de atención en situaciones de crisis, para una adecuada intervención y conocimiento de las personas con comportamientos autodestructivos.",
        "Pensamiento analítico, crítico y autónomo, la salud mental y afectiva. Asumiendo actitudes y valores de responsabilidad, respecto, auto-respeto, colaborativa y sobre todo de comprensión ante el dolor psíquico en el ejercicio clínico y profesional."
      ]},
    admissionProfile: { inline:"", highlights:[]},
    graduationProfile: { inline:"", highlights:[]},
  },
  {
    id: 14,
    type: "Diplomado",
    section: "diplomados",
    name: "Arteterapia",
    nameInPage: "Diplomado en Arteterapia",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "5 meses",
    modules: "5",
    imageIcon: "icons/i13.png",
    fullPayment: "$9,999",
    monthlyPayment: "$ 999",
    introductionToTheProgram: "",
    objetive: "",
    question: "",
    why: { inline:"", highlights:[
        "Próximamente!..."
      ]},
    admissionProfile: { inline:"", highlights:[]},
    graduationProfile: { inline:"", highlights:[]},
  },
  {
    id: 15,
    type: "Diplomado",
    section: "diplomados",
    name: "Psicoterapia gestal en adolescentes",
    nameInPage: "Diplomado en Psicoterapia gestal en adolescentes",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "5 meses",
    modules: "5",
    imageIcon: "icons/i10.png",
    fullPayment: "$9,999",
    monthlyPayment: "$ 999",
    introductionToTheProgram: "",
    objetive: "",
    question: "",
    why: { inline:"", highlights:[
        "Próximamente!..."
      ]},
    admissionProfile: { inline:"", highlights:[]},
    graduationProfile: { inline:"", highlights:[]},
  },
  {
    id: 16,
    type: "Diplomado",
    section: "diplomados",
    name: "Tanatología",
    nameInPage: "Diplomado en Tanatología",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "5 meses",
    modules: "5",
    imageIcon: "icons/i10.png",
    fullPayment: "$9,999",
    monthlyPayment: "$ 999",
    introductionToTheProgram: "",
    objetive: "",
    question: "",
    why: { inline:"", highlights:[
        "La tanatología nos enseña el significado de cada una de las pérdidas que vamos teniendo a lo largo de nuestra vida.",
        "La tanatología ofrece la oportunidad de enfrentar cualquier tipo de pérdida de una mejor manera, en forma positiva, así pues, favorece las herramientas para dar sentido al dolor, y así en el momento que llegue la mayor de las pérdidas, la muerte, se cuente con lo necesario para enfrentarla de una manera favorable y trascendente.",
        "Parte importante de la vida es aprender a ver la muerte como algo natural, de manera consciente y responsable. La actitud ante la muerte depende de la filosofía y las creencias de la cultura que se vive en la sociedad y que tiene cada persona, y de cómo se ha preparado para ello.",
        "La actitud de cada persona ante la muerte, depende de la filosofía, las creencias, la cultura y la sociedad.",
        "Así pues, tanatología ofrece la oportunidad de disfrutar la vida y que le demos o recobremos el valor de las cosas que muchas veces hemos olvidado.",
        "Algunas veces no nos atrevemos a reconocer nuestras propias pérdidas, pensamos que no tienen importancia o que no es necesario procesarlas."
      ]},
    admissionProfile: { inline:"", highlights:[]},
    graduationProfile: { inline:"", highlights:[]},
  },
  {
    id: 17,
    type: "Diplomado",
    section: "diplomados",
    name: "Técnicas de intervención en la depresión y ansiedad infantil y adolescentes",
    nameInPage:
        "Diplomado en Técnicas de intervención en la depresión y ansiedad infantil y adolescentes",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "5 meses",
    modules: "5",
    imageIcon: "icons/i10.png",
    fullPayment: "$9,999",
    monthlyPayment: "$ 999",
    introductionToTheProgram: "",
    objetive: "",
    question: "",
    why: { inline:"", highlights:[
        "Durante la infancia y la adolescencia es común que se presente problemas de ansiedad y depresión, situaciones que alteran el desarrollo y bienestar emocional del individuo. De ahí surge la necesidad como profesionales de la salud mental, de conocer los principales problemas de la ansiedad durante la infancia y la adolescencia.",
        "Así mismo, es importante resaltar que, considerando los datos de la Organización Mundial de la Salud, la depresión es la principal causa mundial de discapacidad.",
        "Es por ello que aprender técnicas de diagnóstico e intervenciones clínicas específicas, que estén encaminadas al manejo de depresión y otros trastornos del estado de ánimo, resultan esenciales para los profesionales de la salud mental.",
        "Derivado de lo anterior la actualización, así como la conceptualización y el manejo de herramientas adecuadas para un efectivo diagnóstico y tratamiento en casos de depresión, ansiedad y otros trastornos del estado de ánimo en pacientes consultantes infanto-juvenil y adultos cobra gran relevancia."
      ]},
    admissionProfile: { inline:"", highlights:[]},
    graduationProfile: { inline:"", highlights:[]},
  },
  {
    id: 18,
    type: "Diplomado",
    section: "diplomados",
    name: "Psicoterapia en la alimentación",
    nameInPage: "Diplomado en Psicoterapia en la alimentación",
    sepCode: "",
    rvoe: "",
    rvoeLink:"",
    videoLink:"",
    duration: "5 meses",
    modules: "5",
    imageIcon: "icons/i7.png",
    fullPayment: "",
    monthlyPayment: "",
    introductionToTheProgram: "",
    objetive: "",
    question: "",
    why: { inline:"", highlights:[
        "Una alimentación saludable ayuda a mantener una buena salud y a prevenir enfermedades no transmisibles como la diabetes, las cardiopatías, los accidentes cerebrovasculares y el cáncer.",
        "El ser humano tiene como una de sus actividades esenciales la alimentación; A través de los años esta ha cambiado, algunas particularidades se van trasmitiendo de generación en generación, con una gran diversidad de costumbres y hábitos alimenticios que nos hay llevado desde un desarrollo físico y mental más adaptable a nuestro medio ambiente, situación que, en algunos casos ha desencadenado una variedad de enfermedades por el simple hecho de comer, de no hacerlo y por la necesidad emocional o física de vernos bien.",
        "Así pues, por los cambios suscitados se han ido adaptando a estos hábitos alimenticios y costumbres diversas en el ámbito de la alimentación, ya que, si bien antes era un placer el sentarse a comer y formaba parte de la convivencia de una familia, actualmente es solo una necesidad que hay que cubrir durante el día a día de nuestras múltiples actividades."
      ]},
    admissionProfile: { inline:"", highlights:[]},
    graduationProfile: { inline:"", highlights:[]},
  },
];

export const bachFeatures = [
  {
    id: "feature-1",
    icon: "careers/Star.svg",
    title: "Fortalece Competencias!",
    content:
        "En la Prepa en Línea fortalecerás competencias que te servirán para trasladarte al nivel superior de estudios y para tener una inserción pronta y exitosa a escenarios laborales.",
  },
  {
    id: "feature-2",
    icon: "careers/Send.svg",
    title: "Domina la tecnologia!",
    content:
        "Con el dominio de la modalidad online fortalecerás tus conocimientos y destrezas para el uso de la tecnología: correo electrónico, procesadores de texto y navegar por internet.",
  },
  {
    id: "feature-3",
    icon: "careers/Shield.svg",
    title: "Organiza tu tiempo!",
    content:
        "La Prepa en Línea te permite organizar tu tiempo para atender actividades personales o laborales y al mismo tiempo prepararte para tu futura vida universitaria.",
  },
  {
    id: "feature-4",
    icon: "careers/Book.svg",
    title: "Domina el auto-aprendizaje!",
    content:
        "Al estudiar en modalidad online, aprenderás a fomentar tu iniciativa para profundizar en los temas de interés, obtendrás disciplina y constancia para concretar tus actividades escolares.",
  },
  {
    id: "feature-5",
    icon: "careers/Bars.svg",
    title: "Lleva tus estudios a otro nivel!",
    content:
        "En la Prepa en Línea fortalecerás competencias que te servirán para trasladarte al nivel superior de estudios y para tener una inserción pronta y exitosa a escenarios laborales.",
  },
];
