import React from "react";
import { Link } from "react-router-dom";
import { academicDegrees } from "../constants/educativeOfferData.js";

const CardWithDetail = (props) => {
  const items = academicDegrees.filter((val) => val.section === props.section);

  return (
    <>
      <section className="text-center mt-5">
        <div className="mx-auto max-w-[85%] grid gap-[30px] grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {items.map((val, index) => {
            const formattedName = val.name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/\s+/g, "-");

            return (
              <div
                className="bg-white-100 shadow-xl p-8 text-center border border-gray-300 rounded-xl  transform transition-transform hover:scale-105"
                key={val.id}
              >
                <div className="flex flex-col justify-between h-full">
                  <div className="flex">
                    <div className="left">
                      <div className="w-[70px] h-[70px] rounded-full bg-primary p-4">
                        <img
                          className="object-cover"
                          src={require(`../assets/images/${val.imageIcon}`)}
                          alt={`card ${index + 1}`}
                        />
                      </div>
                    </div>
                    <div className="ml-3 text-left">
                      <h1 className="text-xl font-medium">
                        {val.name}
                      </h1>
                      <div className="details">
                        <span className="text-primary font-medium text-sm">
                          {val.duration}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-center mt-4">
                    <div className="bg-[#E5E5E5] p-1 rounded mb-2">
                      <h3 className="font-medium text-primary">
                        {val.modules} Módulos
                      </h3>
                    </div>

                    <Link to={`/${props.section}/${formattedName}`}>
                      <button className="outline-btn">Más información !</button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default CardWithDetail;
