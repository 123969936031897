import React from "react";
import Heading from "./common/Heading";

import CardWithDetail from "./CardWithDetail";
import Card from "./Card";

const HAbout = () => {
  return (
    <>
      <section className="homeAbout">
        <div className="mx-auto max-w-[95%] mt-10 p-6 rounded-lg shadow">
          <Heading subtitle="Licenciaturas" title="Licenciaturas en línea" />
          <CardWithDetail section={"licenciaturas"} />
        </div>

        <div className="mx-auto max-w-[95%] mt-10 p-6 rounded-lg shadow">
          <Heading subtitle="Maestrías" title="Encuentra el programa a tu medida" />
          <Card section={"maestrias"} />
        </div>

        <div className="mx-auto max-w-[95%] mt-10 p-6 rounded-lg shadow">
          <Heading subtitle="Diplomados" title="Diplomados en línea" />
          <Card section={"diplomados"} />
        </div>
      </section>
    </>
  );
};

export default HAbout;
