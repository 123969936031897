import React from "react";
import styles, { layout } from "../assets/styles/style";

import { bachFeatures } from "../constants/educativeOfferData";
import robot from "../assets/images/careers/robot.png";
import sep from "../assets/images/icons/sep.svg";
import rvoe from "../assets/images/icons/rvoe.svg";

const Bachillerato = () => {
  return (
      <div className="w-full overflow-hidden">
        <div className={`bg-dimWhite ${styles.flexStart}`}>
          <div className={`${styles.boxWidth}`}>
            <section
                id="home"
                className="flex md:flex-row flex-col pt-4"
            >
              <div
                  className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}
              >
                <div className="flex flex-row justify-between items-center w-full">
                  <h1 className="flex-1 font-semibold text-tPrimary ss:text-[72px] text-[52px] ss:leading-[100.8px] leading-[75px]">
                    Bachillerato
                    <br className="sm:block hidden"/>{" "}
                    <span className="text-gradient">100%</span> en linea
                  </h1>
                </div>

                <p
                    className={`${styles.paragraph} max-w-[470px] mt-5 text-tPrimary`}
                >
                  En la Prepa en Línea fortalecerás competencias que te servirán
                  para trasladarte al nivel superior de estudios y para tener una
                  inserción pronta y exitosa a escenarios laborales.
                </p>

                <div
                    className={`${styles.paragraph} max-w-[470px] mt-5 text-tPrimary`}
                >
                  <ul>
                    <li className="mb-2">
                      Inicia o continua estudios con nosotros.
                    </li>
                    <li className="mb-2">Duración de 24 meses.</li>
                    <li className="mb-2">Mensualidades congeladas</li>
                  </ul>
                </div>
              </div>

              <div
                  className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}
              >
                <img
                    src={robot}
                    alt="billing"
                    className="w-[100%] h-[100%] relative z-[5]"
                />
              </div>
            </section>
          </div>
        </div>

        <div className="flex items-center justify-center pt-10">
          <iframe
              width="840"
              height="475"
              src="https://www.youtube.com/embed/zCpkyN5mZkE"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
          ></iframe>
        </div>

        <div className={`bg-dimWhite ${styles.paddingX} ${styles.flexCenter} text-justify`}>
          <div className={`${styles.boxWidth}`}>
            <section id="features" className={layout.section}>
              <div className={layout.sectionInfo}>
                <h2 className={styles.heading2}>
                  ¿Por que estudiar <br className="sm:block hidden"/>
                  con nostros?
                </h2>
                <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
                  Contamos con una biblioteca virtual en la que encontrarás la
                  totalidad de los recursos bibliográficos que necesites. Además
                  de contar con el respaldo de la plataforma educativa que INCAP
                  tiene para ti, es confiable y se encuentra disponible las 24
                  hrs. los 365 días del año con apoyo técnico, administrativo,
                  académico y socioemocional.
                </p>
                <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
                  Promovemos un ambiente seguro, sano e incluyente que genera
                  una comunidad y espacios positivos para que tengas un sólido
                  crecimiento.
                </p>
                <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
                  Tenemos gran experiencia en orientación vocacional para
                  descubrir tus intereses, aptitudes y elegir la carrera que te
                  apasione.
                </p>
              </div>

              <div className={`${layout.sectionImg} flex-col`}>
                {bachFeatures.map((feature, index) => (
                    <div
                        className={`flex flex-row p-6 rounded-[20px] ${
                            index !== bachFeatures.length - 1 ? "mb-6" : "mb-0"
                        } feature-card`}
                    >
                      <div
                          className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}
                      >
                        <img
                            src={require(`../assets/images/${feature.icon}`)}
                            alt="star"
                            className="w-[50%] h-[50%] object-contain"
                        />
                      </div>
                      <div className="flex-1 flex flex-col ml-3">
                        <h4 className="font-semibold text-tPrimary text-[20px] leading-[23.4px] mb-1">
                          {feature.title}
                        </h4>
                        <p className="font-normal text-tPrimary text-[18px] leading-[24px]">
                          {feature.content}
                        </p>
                      </div>
                    </div>
                ))}
              </div>
            </section>
            <div
                className="mx-auto mb-10 p-6 bg-white rounded shadow-xl md:flex md:space-x-4 justify-center items-center">
            </div>
            <div
                className="mx-auto mb-10 p-6 bg-white rounded shadow-xl md:flex md:space-x-4 justify-center items-center">
              <h2 className="text-2xl font-bold">
                Validez y Reconocimiento:
              </h2>

              <div className="md:flex">
                <p className="text-gray-700 font-semibold">Clave SEP:</p>
                <p className="ml-1">06PBH0068T</p>
              </div>

              <div className="md:flex items-center">
                <p className="text-gray-700 font-semibold">RVOE:</p>
                <p className="ml-1">SE-008-2019-EMS <a
                    href={"https://drive.google.com/file/d/1UcTPYeXSg6_R3QgU6SgWDCJ2dfC6nWDv/view?usp=drive_link"}
                    target={"_blank"} className="button-red"> Consulta aquí </a></p>
              </div>
            </div>
          </div>
        </div>

        <section className={`${styles.flexCenter} my-4`}>
          <div className={`${styles.flexCenter} flex-wrap w-full`}>
            <div
                key="1"
                className={`flex-1 ${styles.flexCenter} sm:min-w-[192px] min-w-[120px] m-5`}
            >
              <img
                  src={sep}
                  alt="client_logo"
                  className="sm:w-[192px] w-[100px] object-contain"
              />
            </div>

            <div
                key="2"
                className={`flex-1 ${styles.flexCenter} sm:min-w-[192px] min-w-[120px] m-5`}
            >
              <img
                  src={rvoe}
                  alt="client_logo"
                  className="sm:w-[192px] w-[100px] object-contain"
              />
            </div>
          </div>
        </section>
      </div>


  );
};

export default Bachillerato;
