import React from "react"
import Hero from "../components/Hero"
import AboutCard from "../components/AboutCard"
import CompleteOffer from "../components/CompleteOffer"
import Testimonal from "../components/Testimonal"

const Home = () => {
  return (
      <>
          <Hero/>
          <AboutCard/>
          <CompleteOffer/>
          {/*<Testimonal /> */}
      </>
  )
}

export default Home
