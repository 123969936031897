import React from "react";
import { Link } from "react-router-dom";
import { academicDegrees } from "../constants/educativeOfferData.js";

const Card = (props) => {
  const items = academicDegrees.filter((val) => val.section === props.section);

  return (
    <>
      <section className="online text-center mt-5">
        <div className="mx-auto max-w-[85%] grid gap-[30px] grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {items.map((val, index) => {
            const formattedName = val.name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/\s+/g, "-");

            return (
              <div
                className="bg-white-100 shadow-xl p-8 boxCard text-center border border-gray-300 rounded-xl transform transition-transform hover:scale-105"
                key={val.id}
              >
                <div className="flex flex-col justify-between h-full">
                  <div className="w-[70px] h-[70px] mx-auto">
                    <img
                      className="object-cover"
                      src={require(`../assets/images/${val.imageIcon}`)}
                      alt={`card ${index + 1}`}
                    />
                  </div>
                  <h1 className="font-medium text-xl my-2">{val.name}</h1>
                  <div className="flex flex-col">
                    <Link to={`/${props.section}/${formattedName}`}>
                      <button className="outline-btn">Visitar</button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Card;
