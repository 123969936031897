export const certificaciones = [
  {
    id: "c1",
    name: "Aplicación Clínica del Arteterapia",
    startDate: "Julio 2023",
    endDate: "Noviembre 2023",
    people: [
      {
        id: "c1p1",
        photo: "./images/back.webp",
        name: "Frania de la Torre González",
      },
      {
        id: "c1p2",
        photo: "./images/back.webp",
        name: "Ma. Raquel Guerrero Acosta",
      },
    ],
  },
  {
    id: "c2",
    name: "Proceso Laboral",
    startDate: "Junio 2023",
    endDate: "Octubre 2023",
    people: [
      {
        id: "c2p1",
        photo: "./images/back.webp",
        name: "Laura Consuelo Guerrero Pérez",
      },
      {
        id: "c2p2",
        photo: "./images/back.webp",
        name: "Margarita Campos Rivas",
      },
    ],
  },
];
