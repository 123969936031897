import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/styles/App.css";

import Header from "./components/common/Header";
import Home from "./pages/Home";
import BachilleratoHome from "./pages/Bachillerato";
import LicenciaturasHome from "./pages/Licenciaturas";
import MaestriasHome from "./pages/Maestrias";
import DiplomadosHome from "./pages/Diplomados";
import CertificacionesHome from "./pages/Certificaciones";
import Contact from "./pages/Contact";
import EducativeOfferDetail from "./pages/EducativeOfferDetail";
import Footer from "./components/common/Footer";
import WhatsAppBtn from "./components/common/WhatsAppBtn";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/bachillerato" element={<BachilleratoHome />} />
          <Route path="/licenciaturas" element={<LicenciaturasHome />} />
          <Route path="/maestrias" element={<MaestriasHome />} />
          <Route path="/diplomados" element={<DiplomadosHome />} />
          <Route
            path="/certificaciones"
            element={<CertificacionesHome />}
          />
          <Route path="/contacto" element={<Contact />} />

          <Route
            path="/licenciaturas/:courseName"
            element={<EducativeOfferDetail />}
          />
          <Route
            path="/maestrias/:courseName"
            element={<EducativeOfferDetail />}
          />
          <Route
            path="/diplomados/:courseName"
            element={<EducativeOfferDetail />}
          />
        </Routes>
        <Footer />
      </Router>
      <WhatsAppBtn />
    </React.Fragment>
  );
}

export default App;
